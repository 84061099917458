/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import * as CONSTANTS from "app/AppConfig/constants";

export const HOME = "/:languagecode";
export const ROOT = "/:langcode";
export const LOGIN = "/:languagecode/login";
export const TWITTER_TOKEN = "/:languagecode/twitter-token";
export const APPLE_TOKEN = "/:lang/apple-token/token=:token";
export const SIGNUP = "/:languagecode/sign-up";
export const MARATHON="/:languagecode/"+CONSTANTS.MARATHON
export const FORGOT_PASSWORD = "/:languagecode/" + CONSTANTS.FORGOT_PASSWORD;
export const FORGOT_PASSWORD_SUCCESS = "/:languagecode/forgot-password-email-link";
export const RESET_PASSWORD_SUCCESS = "/:languagecode/" + CONSTANTS.RESET_PASSWORD_SUCCESS;
export const FORGOT_PASSWORD_EMAIL_RESET = "/:languagecode/reset-password";
export const FORGOT_PASSWORD_MOBILE = "/:languagecode/forgot-password-mobile";
export const FORGOT_PASSWORD_MOBILE_OTP = "/:languagecode/" + CONSTANTS.FORGOT_PASSWORD_MOBILE_OTP;
export const EMAIL_VERIFICATION = "/:languagecode/" + CONSTANTS.CONFIRM_EMAIL;
export const MARATHON_EMAIL="/:languagecode/"+CONSTANTS.MARATHON_EMAIL
export const MARATHON_MOBILE="/:languagecode/"+CONSTANTS.MARATHON_MOBILE
export const MARATHON_CONFIRMATION="/:languagecode/"+CONSTANTS.MARATHON_CONFIRM;
export const MARATHON_ADMIN_LEADERBOARD="/:languagecode/"+CONSTANTS.MARATHON_ADMIN_LEADERBOARD;
export const MARATHON_TERMSOFUSE="/:languagecode/"+CONSTANTS.MARATHON_TERMSOFUSE;
export const MOBILE_VERIFICATION = "/:languagecode/mobile-verification";
export const EMAIL_VERIFICATION_SUCCESSFUL = "/:languagecode/" + CONSTANTS.CONFIRM_EMAIL_CHECK;
export const MOBILE_VERIFICATION_SUCCESSFUL = "/:languagecode/confirm-mobile";
export const EMAIL_VERIFICATION_FAIL = "/:languagecode/email-verification-fail";
export const MOBILE_VERIFICATION_FAIL = "/:languagecode/mobile-verification-fail";
export const NOT_FOUND = "/NotFound";
export const MENU_CONTENT = "/:langcode/:id/:category";
export const STATIC_MENU_CONTENT = "/:langcode/static/:category";
export const API_MENU_CONTENT = "/:langcode/:category";
export const VIDEO_CONTENT = "/:langcode/:type/:id/:name";
export const PLAYER = "/:langcode/" + CONSTANTS.PLAYER + "/:type/:id/:name?";
export const CONTINUE_PLAYER = "/:langcode/" + CONSTANTS.PLAYER + "/:type/:id/:name/Continue-watching";
export const PLAYER_TRAILER = "/:langcode/"+ CONSTANTS.PLAYER + "/:type/:id/:name/trailer/:trailerid?";
export const SEARCH = "/:langcode/search/:term/";
export const MENUCONTENT = "/:langcode/contents/:type/";
export const SEARCH_CAST_GENRE = "/:langcode/search/:category/:name/";
export const MY_ACCOUNT = "/:langcode/my-account";
export const MARATHON_FORM="/:langcode/my-form";
export const MY_SETTINGS = "/:langcode/settings";
export const MY_ACTIVITY = "/:langcode/my-activity/";
export const MY_ACTIVITY_WATCH_REPORT = "/:langcode/my-activity/watch-report";
export const DEVICE_MANAGEMENT = "/:langcode/myTV";
export const MANAGE_ACCOUNT = "/:langcode/manage-account";
export const CHANGE_PASSWORD = "/:langcode/change-password";
export const ABOUT = "/:langcode/static/about-:langcode";
export const PRIVACY_POLICY = "/:langcode/static/privacy-:langcode";
export const PRIVACY_ANDROID_POLICY = "/:langcode/static/privacy-policy-android-:langcode";
export const COOKIE_POLICY = "/:langcode/static/cookie-policy-:langcode";
export const COOKIE_ANDROID_POLICY = "/:langcode/static/cookie-policy-android-:langcode";
export const TERMS_OF_USE = "/:langcode/static/term-:langcode";
export const TERMS_ANDROID="/:langcode/static/term-android-:langcode";
export const CHECKOUT = "/:langcode/checkout";
export const PLANS = "/:langcode/" + CONSTANTS.PLANS;
export const MANAGE_COOKIES = "/:langcode/" + CONSTANTS.MANAGE_COOKIES;
export const PLANS_DESCRIPTION = "/:langcode/" + CONSTANTS.PLANS_DESCRIPTION;
export const RAMADAN_PLANS = "/:langcode/" + CONSTANTS.RAMADAN_PLANS;
export const DEVICE_DESCRIPTION = "/:langcode/" + CONSTANTS.DEVICE_DESCRIPTION;
export const TRANSACTION_STATUS = "/:langcode/transactionstatus/:status";
export const PAYMENT_ENTER_MOBILE = "/:langcode/" + CONSTANTS.PAYMENT_ENTER_MOBILE;
export const PAYMENT_ENTER_OTP = "/:langcode/" + CONSTANTS.PAYMENT_ENTER_OTP;
export const PAYMENT_SMS_CODE = "/:langcode/payment-sms-code";
export const SUBSCRIPTION_TO_WATCH = "/:langcode/subscribe-to-watch";
export const SUBSCRIPTION_TO_WATCH_AD =
  "/:langcode/subscribe-to-watch-without-ad";
export const PAYMENT_OPERATOR = "/:langcode/adyen-enter-details";
export const MY_SUBSCRIPTION = "/:langcode/my-subscription";
export const COUPON_SUCCESS = "/:langcode/coupon-success";
export const DU_SUCCESS = "/:langcode/du-success";
export const DU_SUCCESS_MOBILE = "/:langcode/du-success-mobile";
export const CONTACT_US = "/:langcode/static/contact-:langcode";
export const VIDEO_LIST_PLANS = "/:langcode/" + CONSTANTS.LIST + '/plan=:package_id';
export const IMSAKEYEH = "/imsakeyeh";
export const WIN="/win";
export const NOTFOUNDPAGE = "/notfound"