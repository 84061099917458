/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "app/store/action/";
import * as constants from "app/AppConfig/constants";
import * as common from "app/utility/common";
import { Link } from "react-router-dom";
import oResourceBundle from "app/i18n/";
import Dialog from "core/components/Dialog";
import Button from "core/components/Button";
import RatingStar from "core/components/RatingStar";
import shareFacebookIcon from "app/resources/assets/common/share_facebook.svg";
import shareTwitterIcon from "app/resources/assets/common/share_twitter.svg";
import Plus from "../../../resources/assets/newslider/Plus.svg";
import removePlaylistIcon from "../../../resources/assets/newslider/Minus.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { sendEvents } from "core/GoogleAnalytics/";
import { toast } from "core/components/Toaster/";
import "./index.scss";
import { isMobile } from "react-device-detect";
import Share from "../../../resources/assets/newslider/Share.svg";
import { multiTierContentType } from "app/utility/common";
import {
  REMOVE_PLAYLIST_CATEGORY,
  ADD_PLAYLIST_CATEGORY,
} from "app/AppConfig/constants";
import Moviesimg from "../../../resources/assets/newslider/Movies.svg";
import LiveTvimg from "../../../resources/assets/newslider/Live TV.svg";
import Programsimg from "../../../resources/assets/newslider/Programs.svg";
import Seriesimg from "../../../resources/assets/newslider/Series.svg";
import Playlistimg from "../../../resources/assets/newslider/Playlist.svg";
import { ENABLE_BANNER_ADVERTISEMENT } from "app/AppConfig/features";
import { max } from "moment/moment";
const MODULE_NAME = "BaseContainer";

/**
 * Functional component that renders Video overview
 * @param {Object} props - properties to the component
 * @returns {Component} - Video oberview component
 */

class VideoOverview extends React.PureComponent {
  constructor(props){
    super(props)
     this.state = {
      isRatingDialogOpen: false,
      rating: this.props.data.averageRating,
      showShareIcons: false,
      PageTimer: "",
      seasonLogoDimensions: { width: 0, height: 0 }
    };
  }
 

  componentDidMount() {
    this.playistAPIfired = false;

    this.setState({
      PageTimer: new Date().getTime(),
    });
  }

  /**
   * Component Name - VideoOverview
   * Share button click handler
   * @param null
   * @returns {undefined}
   */
  onShareButtonClick() {
    this.setState((prevState) => ({
      showShareIcons: !prevState.showShareIcons,
    }));
  }

  StopPageTimer() {
    let endTime = new Date();
    return (endTime.getTime() - this.state.PageTimer) / 1000;
  }

  /**
   * Component Name - VideoOverview
   * Remove item from user playlist
   * @param null
   * @returns {undefined}
   */
  fnAnUtherisedHanlder() {
    //Go to login screen
    this.props.history.push(`/${this.props.locale}/${constants.LOGIN}`);
  }

  /**
   * Component Name - VideoOverview
   * Remove item from user playlist
   * @param null
   * @returns {undefined}
   */
  async onAddRemovePlaylist() {
    const { type, locale, target } = this.props;
    const { id, title } = this.props.data;
    //check if the item is already in the playlist or not
    const isItemExists = this.props.aUserPlayList.some(
      (ele) => ele.content.id === id
    );

    const oUserToken = JSON.parse(
      common.getServerCookie(constants.COOKIE_USER_TOKEN)
    );
    if (oUserToken) {
      const isUserSubscribed = await common.isUserSubscribed();
      if (!common.isMENARegion(this.props.sCountryCode) && !isUserSubscribed) {
        //Save the resume path
        const index = window.location.href.indexOf("/" + this.props.locale);
        const currentPath = window.location.href.substring(index);
        common.setCookie(
          constants.RESUME_PATH_COOKIE_NAME,
          currentPath,
          constants.COOKIES_TIMEOUT_NOT_REMEMBER
        );
        common.fnNavTo.call(
          this,
          `/${this.props.locale}/${constants.PLANS_DESCRIPTION}`
        );
        return;
      } else {
        if (!this.playistAPIfired) {
          this.playistAPIfired = true;
          if (isItemExists) {
            //if true delete from playlist
            this.props.fnRemoveItemFromPlayList(
              locale,
              id,
              type,
              target,
              this.fnAnUtherisedHanlder.bind(this),
              () => {
                this.playistAPIfired = false;
                let seasonNumber = null;
                if (this.props.data.seasons) {
                  seasonNumber =
                    this.props.data.seasons[0] &&
                    this.props.data.seasons[0].season_number;
                }
                const { title } = this.props.data;
                //Send analytics event
                sendEvents(
                  REMOVE_PLAYLIST_CATEGORY,
                  type,
                  `${title} ${
                    seasonNumber
                      ? `  | ${oResourceBundle.season} ${seasonNumber}`
                      : ""
                  }`
                );
                common.showToast(
                  constants.MY_PLAYLIST_TOAST_ID,
                  oResourceBundle.removed_from_playlist1 +
                    title +
                    oResourceBundle.removed_from_playlist2,
                  toast.POSITION.BOTTOM_CENTER
                );
              },
              () => {
                this.playistAPIfired = false;
              }
            );
          } else {
            //add to playlist
            this.props.fnAddItemToPlayList(
              locale,
              id,
              type,
              title,
              target,
              this.fnAnUtherisedHanlder.bind(this),
              () => {
                //Success adding to the playlist
                //Send analytics event
                let seasonNumber = null;
                if (this.props.data.seasons) {
                  seasonNumber =
                    this.props.data.seasons[0] &&
                    this.props.data.seasons[0].season_number;
                }
                const { title } = this.props.data;
                //Send analytics event
                sendEvents(
                  ADD_PLAYLIST_CATEGORY,
                  type,
                  `${title} ${
                    seasonNumber
                      ? `  | ${oResourceBundle.season} ${seasonNumber}`
                      : ""
                  }`
                );

                this.playistAPIfired = false;
                common.showToast(
                  constants.MY_PLAYLIST_TOAST_ID,
                  oResourceBundle.added_to_playlist1 +
                    title +
                    oResourceBundle.added_to_playlist2,
                  toast.POSITION.BOTTOM_CENTER
                );
              },
              () => {
                this.playistAPIfired = false;
                common.showToast(
                  constants.MY_PLAYLIST_TOAST_ID,
                  oResourceBundle.something_went_wrong,
                  toast.POSITION.BOTTOM_CENTER
                );
              }
            );
          }
        }
      }
    } else {
      this.playistAPIfired = false;
      const index = window.location.href.indexOf("/" + this.props.locale);
      const currentPath = window.location.href.substring(index);
      common.setCookie(
        constants.RESUME_PATH_COOKIE_NAME,
        currentPath,
        constants.COOKIES_TIMEOUT_NOT_REMEMBER
      );
      this.fnAnUtherisedHanlder();
    }
  }
  /**
   * Component Name - VideoOverview
   * Rating dialog open hanlder
   * @param null
   * @returns {undefined}
   */
  async handleRatingDialogOpen() {
    const oUserToken = JSON.parse(
      common.getServerCookie(constants.COOKIE_USER_TOKEN)
    );
    const { target } = this.props;
    if (oUserToken) {
      //Check user subscribed or not
      const isUserSubscribed = await common.isUserSubscribed();
      // Check if MENA Region or not
      if (!common.isMENARegion(this.props.sCountryCode) && !isUserSubscribed) {
        //Save the resume path
        const index = window.location.href.indexOf("/" + this.props.locale);
        const currentPath = window.location.href.substring(index);
        common.setCookie(
          constants.RESUME_PATH_COOKIE_NAME,
          currentPath,
          constants.COOKIES_TIMEOUT_NOT_REMEMBER
        );
        common.fnNavTo.call(
          this,
          `/${this.props.locale}/${constants.PLANS_DESCRIPTION}`
        );
        return;
      }
      target !== constants.HOME && this.setState({ isRatingDialogOpen: true });
    } else {
      this.fnAnUtherisedHanlder();
    }
  }
  /**
   * Component Name - VideoOverview
   * Rating dialog close hanlder
   * @param null
   * @returns {undefined}
   */
  onRatingDialogClosed() {
    this.setState({ isRatingDialogOpen: false });
  }
  /**
   * Component Name - VideoOverview
   * Rating star click event
   * @param {Number} nextValue - star rating value
   * @param {Number} prevValue - star previuos rating value
   * @param {String} name - star rating name
   * @returns {undefined}
   */
  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  /**
   * Component Name - VideoOverview
   * Rating star chnaged
   * @param {null}
   * @returns {undefined}
   */
  handleRatingChanged() {
    const { rating } = this.state;
    const { type, locale, target } = this.props;
    const { id, title } = this.props.data;
    this.props.fnChangeRating(locale, id, type, title, rating, target, () => {
      this.setState({ isRatingDialogOpen: false });
    });
  }
  /**
   * Component Name - VideoOverview
   * Twitter share button clicked
   * @param {null}
   * @returns {undefined}
   */
  onTwitterShareButtonClick() {
    const { title } = this.props.data;
    let seasonNumber = null;
    if (this.props.data.seasons) {
      seasonNumber = this.props.data.seasons[0].season_number;
    }
    //Send analytics event
    sendEvents(
      constants.SHARE_CATEGORY,
      constants.SHARE_DETAILS_ACTION,
      `${title} ${
        seasonNumber ? `  | ${oResourceBundle.season} ${seasonNumber}` : ""
      }`
    );
  }

  /**
   * Component Name - VideoOverview
   * Twitter share button clicked
   * @param {null}
   * @returns {undefined}
   */
  onFBShareButtonClick() {
    const { title } = this.props.data;
    let seasonNumber = null;
    if (this.props.data.seasons) {
      seasonNumber = this.props.data.seasons[0].season_number;
    }
    //Send analytics event
    sendEvents(
      constants.SHARE_CATEGORY,
      constants.SHARE_DETAILS_ACTION,
      `${title} ${
        seasonNumber ? `  | ${oResourceBundle.season} ${seasonNumber}` : ""
      }`
    );
  }

  time_convert(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    return `${hours}:${minutes}`;
  }

  /**
   * Component Name - VideoOverview
   * Change state based on props
   * Use it wisely
   * @param {Object} nextProps - properties
   * @param {Object} prevState - state
   * @returns {Object} state
   */
  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      nextState.prevProps &&
      nextProps.data.id !== nextState.prevProps.data.id
    ) {
      return {
        rating: Math.round(nextProps.data.averageRating),
        prevProps: nextProps,
        prevState: nextState,
      };
    } else if (
      nextState.isRatingDialogOpen === true &&
      nextState.isRatingDialogOpen !== nextState.prevState.isRatingDialogOpen
    ) {
      return {
        rating: Math.round(nextProps.data.averageRating),
        prevProps: nextProps,
        prevState: nextState,
      };
    }
    // Return null to indicate no change to state.
    return {
      prevProps: nextProps,
      prevState: nextState,
    };
  }
  getAspectRatio(imageSize) {
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767
    const originalWidth = imageSize.width;
    const originalHeight = imageSize.height;
    // const maxHeight = isMobileView ?  50 : 100
    const maxHeight = isMobileView ? 50 : (isTablet ? 70 : 100);   
        
    let newWidth;
    let newHeight;
    if (originalHeight > maxHeight) {
        const aspectRatio = originalWidth / originalHeight;         
        newHeight = maxHeight; 
        newWidth = aspectRatio * newHeight; 
    } else {
        newWidth = originalWidth; 
        newHeight = originalHeight; 
    }
    newWidth = Math.max(newWidth, 1);
    newHeight = Math.max(newHeight, 1);

    return { width: newWidth, height: newHeight };
}

handleLogoLoad = (event) => {
    const width = event.target.naturalWidth;
    const height = event.target.naturalHeight;
    const newDimensions = this.getAspectRatio({ width, height });
    this.setState({ seasonLogoDimensions: newDimensions });
}
  /**
   * Component Name - VideoOverview
   * renders the UI.
   * @param null
   * @returns {undefined}
   */
  render() {
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767
    const detailsBackground =  isMobileView ? (this.props.data.imagery.backdrop) : this.props.data.imagery.backdrop
     const mobileDetailsBackground = this.props.data.imagery.featured_img;
    var bannerimgsrc = {
      backgroundImage: "url(" + detailsBackground + ")",
      // backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",  
      backgroundPosition: "top center",
      // backgroundAttachment: "fixed",  
  
    };

    const episodeItems = this.props.data.seasons && this.props.data.seasons[0];
    const firstEpisodeItem =
      episodeItems &&
      episodeItems.episodes &&
      episodeItems.episodes.length > 0 &&
      episodeItems.episodes[0];
    const firstItemTitle = (firstEpisodeItem
      ? `${firstEpisodeItem.title}-${oResourceBundle.episode}-${firstEpisodeItem.episode_number}`
      : this.props.data.title
    ).replace(/ +/g, "-");

    let CastData = [];

    if (this.props.data && this.props.data.cast) {
      let { main_actor, main_actress, cast } = this.props.data;
      main_actor && CastData.push(main_actor);
      main_actress && CastData.push(main_actress);
      cast.map((item) => {
        CastData.push(item);
      });
    }
    let content_Type = multiTierContentType(this.props.type);
    const content_type = this.props.data.content_type;

    let imageSource;
    let cdetailsContent;
    if (content_type == "movie") {
      imageSource = Moviesimg;
      cdetailsContent = oResourceBundle.movies;
    } else if (content_type == "series") {
      imageSource = Seriesimg;
      cdetailsContent = oResourceBundle.series;
    } else if (content_type == "LiveTV") {
      imageSource = LiveTvimg;
      cdetailsContent = oResourceBundle.LiveTv;
    } else if (content_type == "program") {
      imageSource = Programsimg;
      cdetailsContent = oResourceBundle.programs;
    } else if (content_type == "play") {
      imageSource = Playlistimg;
      cdetailsContent = oResourceBundle.plays;
    }

    return (
      <div className="program-banner-data program-banner-space">
        <div className="banner-src" style={bannerimgsrc}></div>
        <div className="gradient"></div>
        <div className="banner-data">
          <div className="bannermblscreen">
            <img classname="mobile-image" src={detailsBackground} alt="" />
         
          </div>
          <br />
          <div className="show-desktop">
            <div>
              <div className="micro-elements-info">
                <div className="right-mbldata">
                  <a  className="g-data">
                    <img
                      style={{
                        height: "36px",
                        transform: "translateX(20%)",
                      }}
                      src={Share}
                      alt=""
                      onClick={this.onShareButtonClick.bind(this)}
                    />
                  </a>
                  {!this.props.isEpisodesnull && content_Type ? (
                    ""
                  ) : (
                    <a  className="g-data">
                      <img
                        style={{
                          height: "36px",
                          transform: "translateX(20%)",
                        }}
                        src={
                          this.props.aUserPlayList.some(
                            (ele) => ele.content.id === this.props.data.id
                          )
                            ? removePlaylistIcon
                            : Plus
                        }
                        alt=""
                        onClick={this.onAddRemovePlaylist.bind(this)}
                      />
                    </a>
                  )}
                  {this.state.showShareIcons && (
                    <div className="showPopup">
                      <div className="showpopup-icons-wrapper">
                        <FacebookShareButton url={window.location.href}>
                          <Button
                            className="fb-share"
                            icon={shareFacebookIcon}
                            onClick={this.onFBShareButtonClick.bind(this)}
                          >
                            {oResourceBundle.facebook}
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton url={window.location.href}>
                          <Button
                            className="twitter-share"
                            icon={shareTwitterIcon}
                            onClick={this.onTwitterShareButtonClick.bind(this)}
                          >
                            {oResourceBundle.twitter}
                          </Button>
                        </TwitterShareButton>
                        <div className="more-options">
                          <div
                            className="close-icon"
                            onClick={this.onShareButtonClick.bind(this)}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                this.props.locale == "en" ? "show-desktop" : "show-desktopAr"
              }
            >
               {isMobileView ? <div className="gradient3"></div> : ""}
              {!this.props.isEpisodesnull && content_Type ? (
                <Button
                  className="play-btn"
                  onClick={(e) => this.props.onWatchTrailerClick(e)}
                >
                  {oResourceBundle.Watch_Trailer}
                </Button>
              ) : (
                <Button
                  className="play-btn"
                  value={{
                    id: this.props.videoId,
                    type: this.props.type,
                    friendlyUrl: firstItemTitle,
                    premium_type: this.props.premium_type,
                    rights_type: this.props.rights_type,
                  }}
                  onClick={(event) =>
                    this.props.onPlayButtonClick(
                      this.props.premium_type,
                      this.props.rights_type,
                      this.props.sPathToContent,
                      this.props.live_type,
                      event
                    )
                  }
                >
                  {oResourceBundle.play}
                </Button>
              )}
            </div>
          </div>

          <div className="banner-info-data">
            <div className="new-banner-CDcontentimage">
              <img src={imageSource} alt="" className="title-images" />
              <h1 className="new-banner-contentype">{cdetailsContent}</h1>
            </div>

            {this.props.data.imagery.has_season_logo ? (
              <>
              {this.props.data.imagery.has_season_logo ? (
                  <>
                      <div className="cdtitleLogoContainer">
                          <img
                              className="cdtitleLogoImage"
                              alt=""
                              src={this.props.data.imagery.has_season_logo}
                              onLoad={this.handleLogoLoad}
                              width={this.state.seasonLogoDimensions.width}
                              height={this.state.seasonLogoDimensions.height}
                          />
                      </div>
                      <h2 className="contentTitle">
                          {this.props.data.content_type === "movie"
                              ? ""
                              : this.props.locale === "en"
                                  ? this.props.data.seasons && this.props.data.seasons[0]
                                      ? this.props.data.seasons[0].title
                                      : this.props.data.title
                                  : ""}
                      </h2>
                  </>
              ) : (
                 <div className="cdtitleLogoContainer">
                   <h1 className="contentTitleEnCD">{this.props.data.title}</h1>
                 </div> 
              )}
          </>
            ) : (
              // <div className="cdtitleLogoContainer">
              <h1 className="contentTitleEnCD">{this.props.data.title}</h1>
              // </div>
            
            )}
            <div className="content-details-synopsis">
              {this.props.data.synopsis}
            </div>
            <div className="program-info">
              <p>
                <span>{oResourceBundle.starring + ": "}</span>
                {CastData &&
                  CastData.map((ele, index) => (
                    <Link
                      to={encodeURI(
                        `/${this.props.locale}/${constants.SEARCH}/${constants.CAST}/${ele.toLowerCase().replace(/ +/g, "-")}`
                      )}
                      key={Math.random() + ele}
                      className={index === 0 ? "first-item" : ""}
                      aria-label={ele.title}
                    >
                      {ele + (index < CastData.length - 1 ? ", " : "")}{" "}
                    </Link>
                  ))}
              </p>
              <p>
                <span>
                  {oResourceBundle.genres + ": "}

                  {this.props.data.genres &&
                    this.props.data.genres.map((ele, index) => (
                      <Link
                        to={encodeURI(
                          `/${this.props.locale}/${constants.SEARCH}/${constants.GENRE}/${ele.toLowerCase().replace(/ +/g, "-")}`
                        )}
                        key={Math.random() + ele}
                        className={index === 0 ? "first-item" : ""}
                        aria-label={ele.title}
                      >
                        {ele +
                          (index < this.props.data.genres.length - 1
                            ? ", "
                            : "")}{" "}
                      </Link>
                    ))}
                </span>
              </p>
            </div>
          </div>

          <div className="mbl-hide">
            <div>
              <div className="micro-elements-info">
                <div className="detailsPage-actions">
                  <div className="mbl-hide">
                    {!this.props.hidePlayIcon ? (
                      content_Type && !this.props.isEpisodesnull ? (
                        <Button
                          className="play-btn"
                          onClick={(e) => this.props.onWatchTrailerClick(e)}
                        >
                          {oResourceBundle.Watch_Trailer}
                        </Button>
                      ) : (
                        <Button
                          className="play-btn"
                          value={{
                            id: this.props.videoId,
                            type: this.props.type,
                            friendlyUrl: firstItemTitle,
                            premium_type: this.props.premium_type,
                            rights_type: this.props.rights_type,
                          }}
                          onClick={(event) =>
                            this.props.onPlayButtonClick(
                              this.props.premium_type,
                              this.props.rights_type,
                              this.props.sPathToContent,
                              this.props.live_type,
                              event
                            )
                          }
                        >
                          {oResourceBundle.play}
                        </Button>
                      )
                    ) : null}
                  </div>
                  <div className="right-mbldata">
                    {!this.props.isEpisodesnull && content_Type ? (
                      ""
                    ) : (
                      <a  className="g-data">
                        <span
                          className={
                            this.props.locale == "en"
                              ? "addListEn"
                              : "addListAr"
                          }
                        >
                          {oResourceBundle.add_list}
                        </span>
                        <div
                          className={
                            this.props.aUserPlayList.some(
                              (ele) => ele.content.id === this.props.data.id
                            )
                              ? "removePlaylistIcon"
                              : "addonhover"
                          }
                          onClick={this.onAddRemovePlaylist.bind(this)}
                        >
                          {" "}
                        </div>
                        <span
                          className={
                            this.props.locale == "ar"
                              ? "addArList"
                              : "addArListEn"
                          }
                        >
                          {oResourceBundle.add_list}
                        </span>
                      </a>
                    )}
                    <a className="g-data">
                      <span
                        className={
                          this.props.locale == "en" ? "addListEn" : "addListAr"
                        }
                      >
                        {oResourceBundle.share}
                      </span>
                      <div
                        className="shareonhover"
                        onClick={this.onShareButtonClick.bind(this)}
                      ></div>

                      <span
                        className={
                          this.props.locale == "ar"
                            ? "addArList"
                            : "addArListEn"
                        }
                      >
                        {oResourceBundle.share}
                      </span>
                    </a>

                    {this.state.showShareIcons && (
                      <div className="expanded-share">
                        <FacebookShareButton url={window.location.href}>
                          <Button
                            className="fb-share"
                            icon={shareFacebookIcon}
                            onClick={this.onFBShareButtonClick.bind(this)}
                          >
                            {oResourceBundle.facebook}
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton url={window.location.href}>
                          <Button
                            className="twitter-share"
                            icon={shareTwitterIcon}
                            onClick={this.onTwitterShareButtonClick.bind(this)}
                          >
                            {oResourceBundle.twitter}
                          </Button>
                        </TwitterShareButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {isMobile ? <div className="gradient3"></div> : ""} */}
          {ENABLE_BANNER_ADVERTISEMENT && (
            <div
            className={
              isMobile
                ? constants.AD_CLASS_MOBILE
                : constants.AD_CLASS_DESKTOP
            }
              id="maindiv"
              style={!isMobileView ? { margin: "2px 2px 2px 2px",position: "relative", top: "0em" }:{position:"relative",top:"6em" ,zIndex:"3"}}
            ></div>
          )}
        </div>
        <Dialog
          visible={this.state.isRatingDialogOpen}
          onDialogClosed={this.onRatingDialogClosed.bind(this)}
          duration={constants.RATING_DIALOG_ANIMATION_DURATION}
          showCloseButton={false}
          closeOnEsc={true}
          width={constants.RATING_DIALOG_WIDTH}
          height={constants.RATING_DIALOG_HEIGHT}
        >
          <div className="rating-dialog-content">
            <div className="dialog-title">{this.props.data.title}</div>
            <div className="rating-container">
              <RatingStar
                name="rate"
                starCount={5}
                value={this.state.rating}
                renderStarIcon={() => <span className="star" />}
                onStarClick={this.onStarClick.bind(this)}
              />
            </div>
          </div>
          <div className="rating-dialog-actions">
            <Button
              className="dialog-ok-btn"
              onClick={this.handleRatingChanged.bind(this)}
            >
              {oResourceBundle.ok}
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    aUserPlayList: state.aUserPlayList,
    sCountryCode: state.sCountryCode,
  };
};

/**
 * method that maps state to props.
 * Component - HomeScreen
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
  //dispatch action to redux store
  return {
    fnAddItemToPlayList: (
      sLanguageCode,
      sItemId,
      sItemType,
      sTitle,
      sTarget,
      fnAnUtherisedHanlder,
      fnSuccess,
      fnFailure
    ) => {
      dispatch(
        actionTypes.fnAddItemToPlayList(
          sLanguageCode,
          sItemId,
          sItemType,
          sTitle,
          sTarget,
          fnAnUtherisedHanlder,
          fnSuccess,
          fnFailure
        )
      );
    },
    fnRemoveItemFromPlayList: (
      sLanguageCode,
      sItemId,
      sItemType,
      sTarget,
      fnAnUtherisedHanlder,
      fnSuccess,
      fnFailure
    ) => {
      dispatch(
        actionTypes.fnRemoveItemFromPlayList(
          sLanguageCode,
          sItemId,
          sItemType,
          sTarget,
          fnAnUtherisedHanlder,
          fnSuccess,
          fnFailure
        )
      );
    },
    fnChangeRating: (
      sLanguageCode,
      sItemId,
      sItemType,
      sTitle,
      sRating,
      sTarget,
      fnSuccess
    ) => {
      dispatch(
        actionTypes.fnChangeRating(
          sLanguageCode,
          sItemId,
          sItemType,
          sTitle,
          sRating,
          sTarget,
          fnSuccess
        )
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoOverview)
);