import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Import Link if you want navigation

class NotFoundPage extends Component {

  render() {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#0F1822',
      },
      title: {
        fontSize: '8rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        color: 'white',
      },
      subtitle: {
        fontSize: '2rem',
        color: 'white',
        marginBottom: '1rem',
      },
      text: {
        fontSize: '1.2rem',
        color: 'white',
        marginBottom: '2rem',
        maxWidth: '600px',
      },
      button: {
        fontSize: '1rem',
        padding: '10px 20px',
        backgroundColor: 'orange',
        color: '#fff',
        textDecoration: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s ease',
      },
    };

    return (
      <div style={styles.container}>
        <h1 style={styles.title}>404</h1>
        <h2 style={styles.subtitle}>Page Not Found</h2>
        <p style={styles.text}>
          Sorry, the page you are looking for does not exist. It might have been moved or deleted.
        </p>
        <Link to="/" style={styles.button}>
          Go to Homepage
        </Link>
      </div>
    );
  }
}

export default NotFoundPage;
