import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actionTypes from "app/store/action/";
import * as CONSTANTS from "app/AppConfig/constants";
import oResourceBundle from "app/i18n/";
import Moviesimg from "../../../resources/assets/newslider/Movies.svg";
import LiveTvimg from "../../../resources/assets/newslider/Live TV.svg";
import Programsimg from "../../../resources/assets/newslider/Programs.svg";
import Seriesimg from "../../../resources/assets/newslider/Series.svg";
import Playlistimg from "../../../resources/assets/newslider/Playlist.svg";
import "./index.scss";
import * as common from "app/utility/common";
import MoreDetailsIcon from "../../../resources/assets/newslider/moreButton.svg";

class PremiumLayoutD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgs: [],
      premiumPageD: {
        value: "",
        title: "",
        synopsis: "",
        contentTypeImg: "",
        friendly_url: "",
        seasons: "",
        episodes: "",
        hasSeasonLogo: "",
        contentId: "",
        digitalRights: "",
        WatchTrailer: "",
        videoId: "",
      },
      seasonLogoDimensions: { width: 0, height: 0 },
      clickedIndex: 0,
      backdropImages: props.imgs || [],
      mainBackdrop: "",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mobileView: window.innerWidth < CONSTANTS.MOBILE_VIEW_THRESHOLD,
      mobileCarousel: [],
      desktopCarouselLeft: [],
      desktopCarouselRight: [],
    };
    this.autoplayInterval = null;
  }
  componentDidMount() {
    this.initializeAutoplay();
    this.startAutoplay();
    const contentList =
      this.props.oPageContent &&
      this.props.oPageContent.data.featured.playlists &&
      this.props.oPageContent.data.featured.playlists[0].content;
       console.log(contentList,"component Did Mount");
    const premiumThumb = contentList.map((item) => ({
      image: item.imagery.backdrop,
      title: item.title,
      synopsis: item.synopsis,
      contentTypeImg: item.content_type,
      seasons: item.seasons,
      episodes: item.episodes,
      friendly_url: item.friendly_url,
      has_season_logo: item.imagery.has_season_logo,
      contentId: item.id,
      WatchTrailer: item.HasEpisode,
      digitalRights: item.digitalRighttype,
      videoId: item.video_id,
    }));

    console.log(premiumThumb, "premium");

    this.setState({
      premiumPageD: {
        value: premiumThumb[0].image,
        title: premiumThumb[0].title,
        synopsis: premiumThumb[0].synopsis,
        contentTypeImg: premiumThumb[0].contentTypeImg,
        hasSeasonLogo: premiumThumb[0].has_season_logo,
        contentId: premiumThumb[0].contentId,
        WatchTrailer: premiumThumb[0].WatchTrailer,
        digitalRights: premiumThumb[0].digitalRights,
        videoId: premiumThumb[0].videoId,
      },
      imgs: premiumThumb.map((item) => item.image),
      backdropImages: premiumThumb,
      mainBackdrop: premiumThumb[0].image,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.playListData !== this.props.playListData) {
      const contentList = this.props.oPageContent &&
      this.props.oPageContent.data.featured.playlists &&
      this.props.oPageContent.data.featured.playlists[0].content;
      if (contentList) {
        const premiumThumb = contentList.map((item) => ({
          image: item.imagery.backdrop,
          title: item.title,
          synopsis: item.synopsis,
          contentTypeImg: item.content_type,
          seasons: item.seasons,
          episodes: item.episodes,
          friendly_url: item.friendly_url,
          has_season_logo: item.imagery.has_season_logo,
          contentId: item.id,
          WatchTrailer: item.HasEpisode,
          digitalRights: item.digitalRighttype,
          videoId: item.video_id,
        }));
  
        // Update state if the synopsis has changed
        if (premiumThumb[0].synopsis !== prevState.premiumPageD.synopsis) {
          this.setState({
            premiumPageD: {
              value: premiumThumb[0].image,
              title: premiumThumb[0].title,
              synopsis: premiumThumb[0].synopsis,
              contentTypeImg: premiumThumb[0].contentTypeImg,
              hasSeasonLogo: premiumThumb[0].has_season_logo,
              contentId: premiumThumb[0].contentId,
              WatchTrailer: premiumThumb[0].WatchTrailer,
              digitalRights: premiumThumb[0].digitalRights,
              videoId: premiumThumb[0].videoId,
            },
            imgs: premiumThumb.map((item) => item.image),
            backdropImages: premiumThumb,
            mainBackdrop: premiumThumb[0].image,
          });
        }
      }
    }
  }
  
  componentWillUnmount() {
    this.stopAutoplay();
    clearInterval(this.autoplayInterval);
  }
  getAspectRatio(imageSize) {
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767;
    const originalWidth = imageSize.width;
    const originalHeight = imageSize.height;
    // const maxHeight = isMobileView ?  50 : 100
    const maxHeight = isMobileView ? 50 : 100;
    let newWidth;
    let newHeight;
    if (originalHeight > maxHeight) {
      const aspectRatio = originalWidth / originalHeight;
      newHeight = maxHeight;
      newWidth = aspectRatio * newHeight;
    } else {
      newWidth = originalWidth;
      newHeight = originalHeight;
    }
    return { width: newWidth, height: newHeight };
  }
  handleLogoLoad = (event) => {
    const width = event.target.naturalWidth;
    const height = event.target.naturalHeight;
    const newDimensions = this.getAspectRatio({ width, height });
    this.setState({ seasonLogoDimensions: newDimensions });
  };
  updateImageData = (index) => {
    const selectedImage = this.state.backdropImages[index];
    this.setState({
      clickedIndex: index,
      premiumPageD: {
        ...this.state.premiumPageD,
        value: selectedImage.image,
        title: selectedImage.title,
        synopsis: selectedImage.synopsis,
        contentTypeImg: selectedImage.contentTypeImg,
        hasSeasonLogo: selectedImage.has_season_logo,
        friendly_url: selectedImage.friendly_url,
        seasons: selectedImage.seasons,
        contentId: selectedImage.contentId,
        WatchTrailer: selectedImage.WatchTrailer,
        digitalRights: selectedImage.digitalRights,
        videoId: selectedImage.videoId,
      },
    });
  };
  handleImageChange = (newImageUrl) => {
    this.setState({
      premiumPageD: {
        ...this.state.premiumPageD,
        value: newImageUrl,
      },
    });
  };
  handleClick = (index) => {
    this.stopAutoplay();
    this.updateImageData(index);
    this.startAutoplay();
  };
  initializeAutoplay = () => {
    if (this.state.backdropImages.length > 0 && !this.autoplayInterval) {
      this.startAutoplay();
    }
  };
  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      const nextIndex =
        (this.state.clickedIndex + 1) % this.state.backdropImages.length;
      this.updateImageData(nextIndex);
    }, 5000);
  };
  stopAutoplay = () => {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    }
  };
  render() {
    const { premiumPageD, backdropImages, clickedIndex } = this.state;
    const { locale } = this.props;
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767;
    const FeaturedPremiumData =
      this.props.oPageContent &&
      this.props.oPageContent.data.featured.playlists;
    const content_typeImg =
      this.state && this.state.premiumPageD.contentTypeImg;
      const WatchNowButton =  this.props.oPageContent && this.props.oPageContent.data  &&  this.props.oPageContent.data.featured
      .play_watch_trailer;
    const MoreButton =  this.props.oPageContent && this.props.oPageContent.data  &&  this.props.oPageContent.data.featured.more_button;

    let imageSource;
    let cdetailsContent;
    if (content_typeImg == "movie") {
      imageSource = Moviesimg;
      cdetailsContent = oResourceBundle.movies;
    } else if (content_typeImg == "series") {
      imageSource = Seriesimg;
      cdetailsContent = oResourceBundle.series;
    } else if (content_typeImg == "livetv") {
      imageSource = LiveTvimg;
      cdetailsContent = oResourceBundle.LiveTv;
    } else if (content_typeImg == "program") {
      imageSource = Programsimg;
      cdetailsContent = oResourceBundle.programs;
    } else if (content_typeImg == "play") {
      imageSource = Playlistimg;
      cdetailsContent = oResourceBundle.plays;
    }
    return (
      <React.Fragment>
        <div className="Premium-layout-container">
          <div className="synopsis-container">
            <div className="content-type-container">
              <div className="parent_container">
                <div className="content-type-image">
                  <img src={imageSource} alt="" className="content-type-logo" />
                  <h3 className="content-type-name">{cdetailsContent}</h3>{" "}
                </div>
     
                 {this.state.premiumPageD.hasSeasonLogo ? (
                  <>
                    <div
                      className={
                        this.props.locale == "en"
                          ? "season-logo-title-container"
                          : "season-logo-title-containerAr"
                      }
                    >
                      {!isMobileView && (
                        <img
                          className={
                            this.props.locale === "en"
                              ? "titleLogoImage"
                              : "titleLogoImageAr"
                          }
                          src={this.state.premiumPageD.hasSeasonLogo}
                          alt=""
                          onLoad={this.handleLogoLoad}
                          width={this.state.seasonLogoDimensions.width}
                          height={ this.state.seasonLogoDimensions.height}
                        />
                      )}
                      {isMobileView && (
                        <img
                          className={
                            this.props.locale === "en"
                              ? "titleLogos"
                              : "titleLogosAr"
                          }
                          src={this.state.premiumPageD.hasSeasonLogo}
                          alt=""
                          onLoad={this.handleLogoLoad}
                          width={this.state.seasonLogoDimensions.width}
                          height={ this.state.seasonLogoDimensions.height}
                        />
                      )}
                      <h2 className="contentTitleEn">                     
                        {content_typeImg === "movie"
                          ? ""
                          : this.props.locale === "en"
                          ? this.state.premiumPageD.title
                          : ""}
                      </h2>
                    </div>
                  </>
                ) : (
                  <h1
                    className={
                      this.props.locale === "en"
                        ? "contentTitleEnAR"
                        : "contentTitleArabic"
                    }
                  >
                    {this.state.premiumPageD.title}
                  </h1>
                )}               

<div key={this.state.premiumPageD.synopsis} className="synopsis-inner-container">
  {this.state.premiumPageD.synopsis}
</div>

<div className="watchnow-more-container">
{WatchNowButton ? (
                  <div>
                    <button
                      className={
                        this.props.locale === "en" ? "watchNow" : "watchNowAr"
                      }
                      onClick={
                        content_typeImg === "movie" ||
                        content_typeImg === "livetv" ||
                        content_typeImg === "play"
                          ? this.playButtonClick
                          : this.state.premiumPageD.WatchTrailer
                          ? this.playButtonClick
                          : this.onClickTrialers
                      }
                    >
                      {content_typeImg === "movie" ||
                      content_typeImg === "livetv" ||
                      content_typeImg === "play"
                        ? oResourceBundle.play
                        : this.state.premiumPageD.WatchTrailer
                        ? oResourceBundle.play
                        : oResourceBundle.Watch_Trailer}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {MoreButton ? (
                  <div>
                    <img
                      className={
                        this.props.locale == "en"
                          ? "morebutton"
                          : "morebuttonAr"
                      }
                      src={MoreDetailsIcon}
                      alt=""
                      onClick={this.moreButtonClick}
                    />
                  </div>
                ) : (
                  ""
                )}

</div>



























                
              </div>

              {premiumPageD.value && (
                <>
                  <div
                    onClick={() => this.moreButtonClick()}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={backdropImages[clickedIndex].image}
                      className="premiumPageSlider"
                      alt="Dynamic Image"
                    />
                    <div
                      className={
                        locale === "en" ? "Carousel_Image" : "Carousel_ImageAr"
                      }
                    >
                      {backdropImages.map((content, i) => (
                        <div
                          className={
                            locale === "en" ? "thumbnail" : "thumbnailAr"
                          }
                          key={i}
                        >
                          <div
                            className={`carouselClicked ${
                              clickedIndex === i ? "clicked" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleClick(i, i);
                            }}
                            alt={`${content}-${i}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {!isMobileView || isTablet ? (
            <div className= {this.props.locale == "en"? "planspage-gradient":"planspage-gradientAr"}></div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

/**
 * method that maps state to props.
 * Component - Premium LayoutD
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapStateToProps = (state) => {
  return {
    sCountryCode: state.sCountryCode,
    isMENARegion: state.isMENARegion,
    isUserSubscribed: state.bIsUserSubscribed,
    aUserPlayList: state.aUserPlayList,
    oVideoDetailContent: state.oVideoDetailContent,
    aRelatedVideos: state.aRelatedVideos,
    TrailerVideos: state.TrailerVideos,
    aRelatedVideosWithType: state.aRelatedVideosWithType,
    oUserResumablesObject: state.oUserResumablesObject,
    loginDetails: state.loginDetails,
    isPremium: state.isPremium,
    locale: state.locale,
    oPageContent: state.oPageContent,
    premiumPageD: state.premiumPageD,
    locale: state.locale,
    videoDetailLoading: state.videoDetailLoading,
    sCountryCode: state.sCountryCode,
    isMENARegion: state.isMENARegion,
    bPageViewSent: state.bPageViewSent,
    isUserSubscribed: state.bIsUserSubscribed,
  };
};

const mapDispatchToProps = (dispatch) => {
  //dispatch action to redux store
  return {
    fnFetchBucketSelectedItemContent: (
      sLanguageCode,
      sVedeoId,
      sVideoType,
      sCountry,
      sBucketTitle,
      fnSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchBucketSelectedItemContent(
          sLanguageCode,
          sVedeoId,
          sVideoType,
          sCountry,
          sBucketTitle,
          fnSuccess
        )
      );
    },
    fnUpdateResumePagePath: (sPath) => {
      dispatch(actionTypes.fnUpdateResumePagePath(sPath));
    },

    fnFetchSelectedVideoItemContent: (
      sLanguageCode,
      sVedeoId,
      sVideoType,
      sCountry,
      fnSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchSelectedVideoItemContent(
          sLanguageCode,
          sVedeoId,
          sVideoType,
          sCountry,
          fnSuccess
        )
      );
    },
    fnResetVideoItemContent: () => {
      dispatch(
        actionTypes.updateSelectedVideoItemContent({
          oVideoContent: null,
          aRelatedVideos: null,
          aRelatedVideosWithType: null,
          aUserPlayList: [],
        })
      );
    },
    fnFetchTrailerForVideos: (
      sLanguageCode,
      sVideoId,
      sVideoType,
      sCountry,
      fnTrailersSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchTrailerForVideos(
          sLanguageCode,
          sVideoId,
          sVideoType,
          sCountry,
          fnTrailersSuccess
        )
      );
    },
    fnUnmountTrailers: () => {
      dispatch(actionTypes.fnUnmountTrailers());
    },
    fnPageViewSent: () => {
      dispatch(actionTypes.fnPageViewSent());
    },
  
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PremiumLayoutD)
);
