/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const PAGEVIEW_SENT = "PAGEVIEW_SENT";
export const START_VIDEO_INFO_LOADER = "START_VIDEO_INFO_LOADER";
export const STOP_VIDEO_INFO_LOADER = "STOP_VIDEO_INFO_LOADER";
export const START_VIDEO_DETAIL_LOADER = "START_VIDEO_DETAIL_LOADER";
export const STOP_VIDEO_DETAIL_LOADER = "STOP_VIDEO_DETAIL_LOADER";
export const CHANGE_DIRECTION = "CHANGE_DIRECTION";
export const UPDATE_PLATFORM_CONFIG = "UPDATE_PLATFORM_CONFIG";
export const UPDATE_MENU_ITEMS = "UPDATE_MENU_ITEMS";
export const UPDATE_PAGE_CONTENT = "UPDATE_PAGE_CONTENT";
export const DISPLAY_PLAN_CONTENT = "DISPLAY_PLAN_CONTENT";
export const UPDATE_BUCKET_ITEM_VIDEO_INFO = "UPDATE_BUCKET_ITEM_VIDEO_INFO";
export const UPDATE_VIDEO_ITEM_VIDEO_CONTENT =
  "UPDATE_VIDEO_ITEM_VIDEO_CONTENT";
export const UPDATE_ITEM_VIDEO_INFO = "UPDATE_ITEM_VIDEO_INFO";
export const UPDATE_VIDEO_PLAYBACK_STATE = "UPDATE_VIDEO_PLAYBACK_STATE";
export const UPDATE_PLAYER_SCREEN_STATE = "UPDATE_PLAYER_SCREEN_STATE";
export const UPDATE_VIDEO_QUALITY_LEVELS = "UPDATE_VIDEO_QUALITY_LEVELS";
export const UPDATE_RELATED_VIDEOS = "UPDATE_RELATED_VIDEOS";
export const UPDATE_TRAILER_VIDEOS = "UPDATE_TRAILER_VIDEOS";
export const UPDATE_LOGIN_INFO = "UPDATE_LOGIN_INFO";
export const SAVE_NEW_USER_INFO = "SAVE_NEW_USER_INFO";
export const GET_TWITTER_TOKEN = "GET_TWITTER_TOKEN";
export const GET_TWITTER_ACCESS_TOKEN = "GET_TWITTER_ACCESS_TOKEN";
export const UPDATE_LOG_OUT_INFO = "UPDATE_LOG_OUT_INFO";
export const UPDATE_FACEBOOK_LOGIN_INFO = "UPDATE_FACEBOOK_LOGIN_INFO";
export const UPDATE_APPLE_LOGIN_INFO = "UPDATE_APPLE_LOGIN_INFO";
export const UPDATE_USER_PLAYLIST = "UPDATE_USER_PLAYLIST";
export const STORE_USER_PLAYLIST = "STORE_USER_PLAYLIST";
export const UPDATE_ITEM_RATING = "UPDATE_ITEM_RATING";
export const UPDATE_RESUMABLE_CONTENT = "UPDATE_RESUMABLE_CONTENT";
export const GET_FORGOT_PASSWORD_USER_DETAILS =
  "GET_FORGOT_PASSWORD_USER_DETAILS";
export const UPDATE_USER_PLAYLIST_PAGE_CONTENT =
  "UPDATE_USER_PLAYLIST_PAGE_CONTENT";
export const USER_SEARCH_RESPONSE = "USER_SEARCH_RESPONSE";
export const CLEAR_USER_SEARCH_RESPONSE = "CLEAR_USER_SEARCH_RESPONSE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_LOGGED_IN_DEVICES = "UPDATE_LOGGED_IN_DEVICES";
export const UPDATE_COUNTRY_CODE = "UPDATE_COUNTRY_CODE";
export const UPDATE_COUNTRY_LIST = "UPDATE_COUNTRY_LIST";
export const UPDATE_ENGLISH_COUNTRY_LIST = "UPDATE_ENGLISH_COUNTRY_LIST";
export const UPDATE_PAYMENT_SESSION = "UPDATE_PAYMENT_SESSION";
export const UPDATE_ETISALAT_SESSION = "UPDATE_ETISALAT_SESSION";
export const UPDATE_TPAY_SESSION = "UPDATE_TPAY_SESSION";
export const UPDATE_ZAIN_SESSION = "UPDATE_ZAIN_SESSION";
export const UPDATE_TIMWE_SESSION = "UPDATE_TIMWE_SESSION";
export const UPDATE_UNIVERSE_TELECOM_SESSION = "UPDATE_UNIVERSE_TELECOM_SESSION";
export const UPDATE_TELUS_SESSION = "UPDATE_TELUS_SESSION";
export const UPDATE_INFO_SESSION = "UPDATE_INFO_SESSION";
export const UPDATE_TIMWE_ORDERDETAILS ="UPDATE_TIMWE_ORDERDETAILS";
export const UPDATE_TRNSACTION_REFERENCE = "UPDATE_TRNSACTION_REFERENCE";
export const UPDATE_RESUME_PAGE = "UPDATE_RESUME_PAGE";
export const UPDATE_SUBSCRIPTON_PLANS = "UPDATE_SUBSCRIPTON_PLANS";
export const UPDATE_HEADER_MENU_CONTETS = "UPDATE_HEADER_MENU_CONTETS";
export const UPDATE_HEADER_MENU = "UPDATE_HEADER_MENU";
export const UPDATE_GDPR_PAYMENTGATEWAY_LIST = "UPDATE_GDPR_PAYMENTGATEWAY_LIST";
export const UPDATE_USER_SUBSCRIPTON = "UPDATE_USER_SUBSCRIPTON";
export const UPDATE_SELECTED_PLAN = "UPDATE_SELECTED_PLAN";
export const UPDATE_USER_PAYMENT_DETAILS = "UPDATE_USER_PAYMENT_DETAILS";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export const SEND_CONTACT_US_DETAILS = "SEND_CONTACT_US_DETAILS";
export const LOGIN_TO_HOME = "LOGIN_TO_HOME";
export const UPDATE_COUNTRY_PHONE_CODE = "UPDATE_COUNTRY_PHONE_CODE";

export const SET_INTRO_OUTRO="SET_INTRO_OUTRO"

export const SET_BASE_CONTENT_TYPE="SET_BASE_CONTENT_TYPE";
export const SET_WATCH_SESSION="SET_WATCH_SESSION";
export const SET_PAGE_REDIRECT="SET_PAGE_REDIRECT";
export const SET_LEADERBOARD_DATA="SET_LEADERBOARD_DATA";
export const SET_DIALCODE="SET_DIALCODE";
export const SET_MOBILE="SET_MOBILE";
export const SET_SELECTEDCOUNTRY="SET_SELECTEDCOUNTRY";
export const SET_EVENT="SET_EVENT";
export const SET_EVENTCOUNTRY="SET_EVENTCOUNTRY";
export const SET_MARATHON_LIVE="SET_MARATHON_LIVE"

export const NOT_FOUND = 'NOT_FOUND';